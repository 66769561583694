<div class="app-search-panel" #fullSearchPanel cdkOverlayOrigin>
  <app-fulltext-search
    [projectfilter]="projectfilter"
    [(limitToProject)]="limitToProject"
    (search)="emitSearch($event)">
  </app-fulltext-search>

  <!-- Do not display advanced and expert search on mobile devices smaller than tablet -->
  <div class="advanced-expert-buttons" [class.with-project-filter]="projectfilter">
    <button mat-flat-button class="hint" [class.active]="showHint" (click)="openPanelWithBackdrop('hint')">
      <mat-icon matPrefix>lightbulb</mat-icon>
      <span class="desktop-only">How to search</span>
    </button>
    <span class="fill-remaining-space"></span>

    <!-- expert search button: if expert === true -->
    <button mat-flat-button *ngIf="expert" [class.active]="showExpert" (click)="openPanelWithBackdrop('expert')">
      <mat-icon matPrefix>school</mat-icon>
      <span class="desktop-only">expert</span>
    </button>
  </div>
</div>

<!-- full-text search menu -->
<ng-template #searchMenu>
  <div class="app-search-menu with-advanced-search" [class.with-project-filter]="projectfilter">
    <div class="app-menu-header">
      <span class="app-menu-title">
        <h4 [ngSwitch]="true">
          <span *ngSwitchCase="showAdvanced">Advanced search</span>
          <span *ngSwitchCase="showExpert">Expert search</span>
          <span *ngSwitchCase="showHint">How to search</span>
        </h4>
      </span>
      <span class="fill-remaining-space"></span>
      <span class="app-menu-close">
        <button mat-icon-button (click)="closeMenu()">
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </div>
    <div class="app-menu-content" [ngSwitch]="true">
      <app-expert-search *ngSwitchCase="showExpert" (search)="emitSearch($event)"></app-expert-search>
      <app-hint *ngSwitchCase="showHint" [topic]="'search'"></app-hint>
    </div>
  </div>
</ng-template>
