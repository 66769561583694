<div class="content extra-large middle">
  <div class="overview">
    <div *ngIf="isProjectsLoading$ | async" [@replaceAnimation]>
      <div class="title-bar admin">
        <div class="title">
          <p>Browse Research Projects</p>
        </div>
      </div>
      <div class="project-tiles">
        <div *ngFor="let item of loadingTiles" class="project-tile-container">
          <app-project-tile isLoading="true"></app-project-tile>
        </div>
      </div>
    </div>

    <!-- user is a system admin -->
    <div *ngIf="(allProjects$ | async).length > 0 && ((isSysAdmin$ | async) && (isProjectsLoading$ | async) === false)">
      <!-- list all projects -->
      <div class="title-bar admin">
        <div class="title">
          <p>Browse Research Projects</p>
        </div>
      </div>
      <div class="project-tiles">
        <div *ngFor="let proj of allProjects$ | async; trackBy: trackByFn" class="project-tile-container">
          <app-project-tile [project]="proj" [sysAdmin]="true"></app-project-tile>
        </div>
      </div>
    </div>
    <!-- user is logged in and not a system admin -->
    <div *ngIf="(isSysAdmin$ | async) === false && (user$ | async)">
      <ng-container *ngIf="(userActiveProjects$ | async).length > 0">
        <div class="title-bar">
          <div class="title">
            <p>My Projects</p>
          </div>
        </div>
        <div class="project-tiles">
          <div *ngFor="let userProj of userActiveProjects$ | async; trackBy: trackByFn" class="project-tile-container">
            <app-project-tile [project]="userProj" [sysAdmin]="false"></app-project-tile>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(userOtherActiveProjects$ | async).length > 0">
        <div class="title-bar other-projects">
          <div class="title">
            <p>Browse Other Research Projects</p>
          </div>
        </div>
        <div class="project-tiles">
          <div
            *ngFor="let otherProj of userOtherActiveProjects$ | async; trackBy: trackByFn"
            class="project-tile-container">
            <app-project-tile [project]="otherProj" [sysAdmin]="false"></app-project-tile>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- user is not logged in -->
    <div
      *ngIf="((allActiveProjects$ | async).length > 0) && (((isProjectsLoading$ | async) === false) && user$ | async | isFalsy)">
      <!-- list all projects -->
      <div class="title-bar admin">
        <div class="title">
          <p>Browse Research Projects</p>
        </div>
      </div>
      <div class="project-tiles">
        <div *ngFor="let proj of allActiveProjects$ | async; trackBy: trackByFn" class="project-tile-container">
          <app-project-tile [project]="proj" [sysAdmin]="false"></app-project-tile>
        </div>
      </div>
    </div>
  </div>
</div>
