<div class="property-info">
  <div class="upper-prop-container">
    <div mat-line class="title">
      <span
        class="icon"
        *ngIf="propType"
        [matTooltip]="propType?.group + ': ' + propType?.label + ' (' + propDef.id.split('#')[1] + ')'"
        matTooltipPosition="above">
        <mat-icon class="type">{{ propType?.icon }}</mat-icon>
      </span>
      <span
        class="label"
        [matTooltip]="propDef.comment"
        matTooltipPosition="above"
        matTooltipClass="multi-line-tooltip">
        <span data-cy="property-label">{{ propDef.label ? propDef.label : propDef.id }}</span>
      </span>
      <!-- property is linked to a class or to a list: display connected class or list -->
      <span
        class="attribute additional-info"
        *ngIf="propAttribute"
        [matTooltip]="propAttributeComment"
        matTooltipPosition="above"
        [innerHTML]="'&rarr;&nbsp;' + propAttribute">
      </span>
    </div>
    <div class="edit-menu">
      <span class="menu-icon">
        <mat-icon
          [matMenuTriggerFor]="classInfoMenu"
          class="menu-icon-button"
          (click)="canBeRemovedFromClass()"
          *ngIf="userCanEdit"
          >more_vert</mat-icon
        >
      </span>
    </div>
  </div>
  <div mat-line class="lower-prop-container">
    <span
      [matTooltip]="'id: ' + propDef.id"
      matTooltipPosition="above"
      matTooltipClass="wide-tooltip"
      class="mat-caption">
      {{ propDef.id | split: '#':1 }}
    </span>
    <span class="fill-remaining-space center"></span>
    <span class="cardinality-toggles">
      <app-multiple-slide-toggle
        [control]="cardinalityControl"
        [label]="'multiple'"
        [disabled]="!userCanEdit"
        (afterCardinalityChange)="afterCardinalityChange($event)"></app-multiple-slide-toggle>
      <app-required-slide-toggle
        [control]="cardinalityControl"
        [label]="'required'"
        [disabled]="!userCanEdit"
        (afterCardinalityChange)="afterCardinalityChange($event)"></app-required-slide-toggle>
    </span>
  </div>
</div>

<mat-menu #classInfoMenu="matMenu">
  <div class="remove-menu-wrapper">
    <button
      mat-menu-item
      [disabled]="!propCanBeRemovedFromClass"
      (click)="removePropertyFromClass.emit({iri: propDef.id, label: propDef.label})">
      <mat-icon>link_off</mat-icon>
      <ngx-skeleton-loader
        *ngIf="propCanBeRemovedFromClass === undefined || propCanBeRemovedFromClass === null"
        count="1"
        appearance="line"
        class="skeleton"
        [theme]="{
                        'margin-bottom': 0,
                        'vertical-align': 'middle',
                        'min-width' : '16em'
                    }">
      </ngx-skeleton-loader>
      <span *ngIf="propCanBeRemovedFromClass !== undefined">remove property from class</span>
    </button>
  </div>
</mat-menu>
