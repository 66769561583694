<div [ngSwitch]="data.mode">
  <!-- Update password -->
  <div *ngSwitchCase="'editPassword'">
    <app-dialog-header [title]="data.user.username" [subtitle]="'appLabels.form.user.title.password' | translate">
    </app-dialog-header>
    <app-password-form [user]="data.user" (closeDialog)="dialogRef.close()"></app-password-form>
  </div>

  <!-- Manage user project membership -->
  <div *ngSwitchCase="'manageProjectMembership'">
    <app-dialog-header [title]="data.user.username" [subtitle]="'Manage project membership'"></app-dialog-header>
    <app-membership [user]="data.user" (closeDialog)="dialogRef.close()"></app-membership>
    <mat-dialog-actions>
      <span class="fill-remaining-space"></span>
      <button mat-button color="primary" class="cancel-button center" [mat-dialog-close]="false">Close</button>
    </mat-dialog-actions>
  </div>

  <!-- Ontology editor: create new ontology -->
  <div *ngSwitchCase="'createOntology'">
    <app-dialog-header [title]="data.title" [subtitle]="'Create new'"></app-dialog-header>
    <app-ontology-form [projectUuid]="data.project" (closeDialog)="dialogRef.close($event)"></app-ontology-form>
  </div>

  <div *ngSwitchCase="'editOntology'">
    <app-dialog-header [title]="data.title" [subtitle]="'Customize data model'" (closed)="dialogRef.close()">
    </app-dialog-header>
    <app-ontology-form
      [iri]="data.id"
      [projectUuid]="data.project"
      (closeDialog)="dialogRef.close($event)"></app-ontology-form>
  </div>

  <div *ngSwitchCase="'addRegion'">
    <app-dialog-header [title]="data.title" [subtitle]="data.subtitle"></app-dialog-header>
    <app-add-region-form [resourceIri]="data.id"></app-add-region-form>
  </div>

  <div *ngSwitchCase="'replaceFile'">
    <app-dialog-header [title]="data.title" [subtitle]="data.subtitle"></app-dialog-header>
    <mat-dialog-content class="form-content">
      <app-replace-file-form
        [representation]="data.representation"
        [propId]="data.id"
        (closeDialog)="dialogRef.close($event)"></app-replace-file-form>
    </mat-dialog-content>
  </div>
  <div *ngSwitchCase="'linkResources'">
    <app-dialog-header [title]="data.title" [subtitle]="'Link resources'"></app-dialog-header>
    <mat-dialog-content>
      <app-resource-link-form
        [resources]="data.selectedResources"
        (closeDialog)="dialogRef.close($event)"></app-resource-link-form>
    </mat-dialog-content>
  </div>

  <!-- general error message -->
  <div *ngSwitchCase="'error'">
    <app-status [status]="data.id" [comment]="data.comment"></app-status>
  </div>

  <!-- Unknown method (or not yet defined) -->
  <!-- Default dialog content component -->
  <div *ngSwitchDefault>
    <app-dialog-header [title]="'Not yet implemented'" [subtitle]="'ERROR 400'" (closed)="dialogRef.close()">
    </app-dialog-header>
    <p class="todo" [innerHtml]="notYetImplemented"></p>
    <mat-dialog-actions>
      <span class="fill-remaining-space"></span>
      <button mat-button mat-dialog-close class="cancel-button center" [mat-dialog-close]="false">OK</button>
      <span class="fill-remaining-space"></span>
    </mat-dialog-actions>
  </div>
</div>
